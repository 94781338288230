import React, { createContext, useEffect, useState } from "react";
import defaultLogo from "../../assets/images/default_logo.png";

const CompanyContext = createContext();

const CompanyProvider = ({ children }) => {

  const [companyDetails, setCompanyDetails] = useState({
    title: localStorage.getItem("title"),
    logo: localStorage.getItem("logo"),
    favicon: localStorage.getItem("favicon"),
    bgimage: localStorage.getItem("bgimage"),
  });

  const currentTime = Date.now();

  const updateCompanyDetails = (bUrl, newDetails) => {  
    const _BASE_URL=  bUrl;
      const name = newDetails.title;
      const logoUrl = `${_BASE_URL}${newDetails.logo}?${currentTime}`;
      const faviconUrl = `${_BASE_URL}${newDetails.favicon}?${currentTime}`;
      const bgImageUrl = `${_BASE_URL}${newDetails.bgimage}?${currentTime}`;
      setCompanyDetails({
        title: name,
        logo: logoUrl,
        favicon: faviconUrl,
        bgimage: bgImageUrl,
      });
      localStorage.setItem("title", name);
      localStorage.setItem("logo", logoUrl);
      localStorage.setItem("favicon", faviconUrl);
      localStorage.setItem("bgimage", bgImageUrl);  
  };
  
  useEffect(() => {
    setCompanyDetails({
      title: localStorage.getItem("title"),
      logo: localStorage.getItem("logo") || defaultLogo,
      favicon: localStorage.getItem("favicon"),
      bgimage: localStorage.getItem("bgimage"),
    });
  }, []);

  return (
    <CompanyContext.Provider value={{ companyDetails, updateCompanyDetails }}>
      {children}
    </CompanyContext.Provider>
  );
};

export { CompanyProvider, CompanyContext };
